'use client';

import { useEffect, useRef, useState } from "react"
import { twMerge } from "tw-merge"

export default function AnimatedRings(
    {
        rings = 2,
        ratio = [0.6, 0.8],
        ratioDimension = "auto",
        className,
        opacityFactor = 0.4,
        ringClassName,
    }
) {

    className = twMerge(
        `absolute grid place-items-center pointer-events-none m-auto max-w-screen ${className || ""}`
    )

    const containerRef = useRef(null)
    const [parentDimensions, setParentDimensions] = useState({width: 0, height: 0})

    const getDimensionsStyleOfRing = (index) => {
        const dimensionsStyle = {
            width: 0,
            height: 0,
        }
        if(ratioDimension === "auto"){
            if(parentDimensions.height <= parentDimensions.width) {
                dimensionsStyle.width = (parentDimensions.height * ratio[index]);
                dimensionsStyle.height = (parentDimensions.height * ratio[index]);
            } else {
                dimensionsStyle.width = (parentDimensions.width * ratio[index]);
                dimensionsStyle.height = (parentDimensions.width * ratio[index]);
            }
        } else if (ratioDimension === "width") {
            dimensionsStyle.width = (parentDimensions.width * ratio[index]);
            dimensionsStyle.height = (parentDimensions.width * ratio[index]);
        } else {
            dimensionsStyle.height = (parentDimensions.height * ratio[index]);
            dimensionsStyle.width = (parentDimensions.height * ratio[index]);
        }

        return dimensionsStyle;

    }

    useEffect(() => {
        const container = containerRef.current
        if(container && container.parentElement) {
            const parent = container.parentElement
            if(parent.style.position !== "absolute") parent.style.position = "relative"

            setParentDimensions({
                width: parent.clientWidth,
                height: parent.clientHeight,
            })
        }
    },[])

    return (
        <div ref={containerRef} className={className} aria-hidden="true">
            {
                Array.from({ length: rings }, (_, index) => {
                    const dimensions = getDimensionsStyleOfRing(index)
                    const transparency = 1 - ((rings - index) * (opacityFactor || 1 / (rings * 2)))
                    return(
                        <div
                            //TODO: Replace with a random uuid
                            key={index}
                            style={{
                                ...dimensions,
                                opacity:transparency,
                                top: (dimensions.height / 2) * -1,
                                left: (dimensions.width / 2) * -1,
                            }}
                            className={
                                twMerge(
                                    `rounded-full absolute ring-1 ring-opacity-50 ring-primary ${ringClassName || ""}`
                                )
                            }
                        >
                        </div>
                    )
                }
            )}
        </div>
    )
}