import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CallInterface from './CallInterface';
import Header from './Header';
import PoweredBy from './PoweredBy';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header/>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}/>
        <Route path='/call' element={<CallInterface/>}/>
      </Routes>
    </BrowserRouter>
    <PoweredBy/>
  </React.StrictMode>
);
