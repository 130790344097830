
import { useNavigate } from "react-router-dom";
import AnimatedRings from "./AnimatedRings";
import FAQS from "./FAQs";

function App() {

  const navigate = useNavigate()

  const navigateToCall = () => {
    navigate("/call", {
      state: {
        connectCall: true
      }
    })
  }

  return (
    <div className="bg-app-home-hero-bg-gradient font-inter grid">

      <main className="grid justify-center grid-rows-[auto_1fr_auto] z-1 overflow-hidden">
        <AnimatedRings opacityFactor={"0.4"} className={"-z-1 justify-self-center"} ratioDimension="width"/>

        <div className="flex flex-col items-center gap-2 mt-8 text-app-home-hero-heading">
          <h1 className="text-6xl font-bold">Welcome to ORI's Webcall</h1>
          <h2 className="text-2xl">Seamless, web-based AI audio calls at your fingertips.</h2>
        </div>

        <div className="bg-white flex flex-col gap-8 p-8 place-self-center rounded-3xl border border-dashed border-border-off">
          <h2 className="text-3xl justify-self-center font-bold">Experience our Tesla voicebot</h2>

          <FAQS limit={5}/>

          <button onClick={navigateToCall} className="bg-app-home-hero-cta text-center text-white py-3 px-8 text-xl font-semibold w-full rounded-full">Start a Call!  📞</button>
          
        </div>


      </main>

    </div>
  );
}

export default App;
