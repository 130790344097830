const Faqs = [
    "What is Tesla's Autopilot?",
    "How long does it take to charge a Tesla?",
    "What is the Tesla Model S, and how does it work?",
    "What is the Tesla Model X, and how does it work?",
    "What is the range of a Tesla vehicle?",
    "Are there new Tesla vehicle models or features expected soon?",
    "What is Tesla's mission statement?",
    "How does Tesla contribute to sustainability and environmental conservation?",
  ]

export default function FAQS({size = "base", limit}){
    return(
        <div className="flex flex-col gap-3">
            <h4 className={`${size === "base" ? "text-xl" : "text-lg"} font-semibold`}>Here are some FAQs for you to help you get started</h4>
            <div className="flex ml-2 flex-col gap-2">
                {Faqs.slice(Faqs.length - limit, Faqs.length).map((faq, index) => (
                <div key={index} className={`flex gap-2 ${size === "base" ? "text-base" : "text-sm"}`}>
                    <span className="font-bold text-black">{index+1}.</span>
                    <span>{faq}</span>
                </div>
                ))}
            </div>
        </div>
    )
}